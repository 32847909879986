import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';
import { useTitle } from 'react-use';
import { $path } from 'remix-routes';

import { GamePackUtils } from '../components/Game/GamePack/utils';
import { CheckoutCustomPay, OTPUtils } from '../components/OneTimePurchase';
import { makeTitle } from '../utils/common';
import { useCheckoutLoaderData } from './checkout.client';

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const url = new URL(action.request.url);

  const headcount = OTPUtils.ParseHeadcount(url.searchParams.get('headcount'));

  return {
    headcount,
  };
};

export function Component() {
  const { pack } = useCheckoutLoaderData();
  const { headcount } = useLoaderData<typeof clientLoader>();

  useTitle(makeTitle(`Payment | Purchase ${pack.name}`));

  const returnUrl = GamePackUtils.IsLive(pack)
    ? $path('/checkout/enter-name', window.location.search)
    : $path('/checkout/schedule-planning-call', window.location.search);
  const invoiceReturnUrl = `${window.origin}/checkout/completed${window.location.search}`;

  return (
    <CheckoutCustomPay
      pack={pack}
      headcount={headcount}
      returnUrl={returnUrl}
      invoiceReturnUrl={invoiceReturnUrl}
    />
  );
}
