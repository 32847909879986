import { ErrorMessage } from '@hookform/error-message';
import { PaymentElement, useCustomCheckout } from '@stripe/react-stripe-js';
import {
  type FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useEffectOnce, useInterval } from 'react-use';

import {
  type DtoGamePack,
  type ModelsGamePackPrice,
} from '@lp-lib/api-service-client/public';

import { useOneTimePurchaseAnalytics } from '../../analytics/oneTimePurchase';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useTimezone } from '../../hooks/useTimezone';
import { apiService } from '../../services/api-service';
import { type GamePack } from '../../types/game';
import { fromDTOGamePack } from '../../utils/api-dto';
import { err2s } from '../../utils/common';
import { formatCurrency } from '../../utils/currency';
import { TimeUtils } from '../../utils/time';
import { GamePackUtils } from '../Game/GamePack/utils';
import { Loading } from '../Loading';
import { CompaniesUsingLunaPark } from '../Marketing/CompaniesUsingLunaPark';
import { SocialProofJennifer } from '../Marketing/SocialProof';
import { usePromotionCode } from '../Payment/usePromotionCode';
import { OTPCheckoutCustomProvider } from './OTPCheckoutCustomProvider';
import { OTPCheckoutLayout } from './OTPCheckoutLayout';
import { useCheckoutModalStack, useExitIntent } from './OTPCheckoutModals';
import { OTPCheckoutSidebar } from './OTPCheckoutSidebar';

function ContactSales(props: { headcount: number }) {
  const { headcount } = props;

  const analytics = useOneTimePurchaseAnalytics();
  const trackClick = useLiveCallback(() => {
    analytics.trackEventPurchaseContactSalesClicked();
  });

  return (
    <div className='w-full flex justify-center'>
      <p className='text-sms font-medium text-center'>
        Have more questions?{' '}
        <a
          href={
            headcount > 10
              ? 'https://meetings.hubspot.com/arlen-marmel/luna-park-intro-call'
              : 'https://lunapark.com/live-chat/'
          }
          className='text-primary hover:underline tracking-wide'
          target='_blank'
          rel='noreferrer noopener'
          onClick={trackClick}
        >
          Contact Sales
        </a>
      </p>
    </div>
  );
}

function CompletionTimer(props: {
  ready: boolean;
  seconds: number;
  setSeconds: React.Dispatch<React.SetStateAction<number>>;
  onTimeout: () => void;
  hasDateTime?: boolean;
}) {
  const { ready, seconds, setSeconds, onTimeout } = props;

  useEffect(() => {
    if (seconds === 0) {
      onTimeout();
    }
  }, [seconds, onTimeout]);

  useInterval(
    () => {
      setSeconds((prev) => prev - 1);
    },
    ready && seconds > 0 ? 1000 : null
  );
  const formattedTime = TimeUtils.DurationFormattedHHMMSS(
    seconds * 1000,
    false
  );
  return (
    <div className='w-full text-center'>
      Complete your booking in{' '}
      <span className='tabular-nums'>{formattedTime}</span> to confirm your
      purchase
    </div>
  );
}

const checkoutDurationSeconds = 600;

function useCompletionTimer(
  ready: boolean,
  durationSeconds = checkoutDurationSeconds
) {
  const [showCompletionTimer, setShowCompletionTimer] = useState(true);
  const [seconds, setSeconds] = useState(durationSeconds);
  const [modal, triggerModal] = useCheckoutModalStack();
  const handleTimeout = useCallback(() => {
    setShowCompletionTimer(false);
    triggerModal();
  }, [triggerModal]);
  useExitIntent(triggerModal);

  const timer = showCompletionTimer ? (
    <CompletionTimer
      ready={ready}
      seconds={seconds}
      setSeconds={setSeconds}
      onTimeout={handleTimeout}
    />
  ) : (
    <p className='text-base font-normal text-center'>Complete your booking</p>
  );

  return { modal, timer };
}

function CheckoutWithCardForm(props: {
  pack: GamePack;
  returnUrl: string;
  ready: boolean;
  setReady: (ready: boolean) => void;
  timer: JSX.Element;
  headcount: number;
}) {
  const { pack, returnUrl, ready, setReady, timer, headcount } = props;

  const { confirm, canConfirm, total } = useCustomCheckout();
  const analytics = useOneTimePurchaseAnalytics();

  const {
    call: handleSubmit,
    state: {
      state: { isRunning },
      error,
    },
  } = useLiveAsyncCall(async (event: FormEvent) => {
    event.preventDefault();

    analytics.trackEventPurchasePaymentSubmitted();

    const { error } = await confirm();
    if (error) {
      analytics.trackEventPurchasePaymentFailed({
        errType: error.type,
        errCode: error.code,
        errMsg: error.message,
      });
      throw new Error(error.message);
    }

    // wait for the payment to be processed
    for (let i = 0; i < 8; i++) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const resp = await apiService.otp.queryLogs();
      if (resp.data.logs.some((log) => log.objectId === pack.id)) {
        console.log('Payment processed after ' + i + ' seconds');
        break;
      }
    }

    window.location.replace(returnUrl);
  });

  return (
    <form
      onSubmit={handleSubmit}
      className='
        w-140 2xl:w-160 rounded-2.5xl bg-payment bg-cover px-18 py-10 
        backdrop-blur-sm border border-[#6e6e6e] flex flex-col gap-7.5
      '
    >
      <div>
        <p className='text-2xl font-bold text-center'>Purchase Your Event</p>
        {timer}
      </div>

      <PaymentElement
        options={{
          layout: 'tabs',
        }}
        onReady={() => setReady(true)}
        className='text-white'
      />

      <div>
        {error && (
          <div className='mb-1 w-full h-5 text-sms text-red-002 text-center'>
            {error.message}
          </div>
        )}
        <button
          type='submit'
          disabled={isRunning || !ready || !canConfirm}
          className={`btn-primary w-full h-12.5 flex justify-center items-center gap-2 ${
            ready ? '' : 'hidden'
          }`}
        >
          {isRunning && <Loading text='' />}
          {`Pay ${formatCurrency(total.total / 100, true)}`}
        </button>
        <div className='mt-2 flex justify-center items-center '>
          <ContactSales headcount={headcount} />
        </div>
      </div>
    </form>
  );
}

type CheckoutWithInvoiceFormData = {
  email: string;
};

function CheckoutWithInvoiceForm(props: {
  pack: GamePack;
  packPrice: ModelsGamePackPrice;
  returnUrl: string;
  ready: boolean;
  setReady: (ready: boolean) => void;
  timer: JSX.Element;
}) {
  const { pack, packPrice, returnUrl, setReady, timer } = props;

  const [promotionCode] = usePromotionCode();

  const analytics = useOneTimePurchaseAnalytics();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CheckoutWithInvoiceFormData>({
    defaultValues: {
      email: '',
    },
  });

  const {
    call: submit,
    state: {
      state: { isRunning },
      error,
    },
  } = useLiveAsyncCall(async (data: CheckoutWithInvoiceFormData) => {
    const resp = await apiService.gamePack.createInvoice(pack.id, {
      email: data.email,
      priceId: packPrice.id,
      promotionCode,
    });
    return resp.data.invoiceId;
  });

  const [done, setDone] = useState(false);
  const processing = isRunning || done;

  const onSubmit = handleSubmit(async (data) => {
    analytics.trackEventPurchaseSendInvoiceClicked();
    const invoiceId = await submit(data);
    if (!invoiceId) return;
    setDone(true);
    const url = new URL(returnUrl);
    url.searchParams.set('invoice-id', invoiceId);
    window.location.replace(url.toString());
  });

  useEffectOnce(() => setReady(true));

  return (
    <form
      onSubmit={onSubmit}
      className='w-140 2xl:w-160 rounded-2.5xl bg-payment bg-cover px-18 py-10 
      backdrop-blur-sm border border-[#6e6e6e]'
    >
      <div>
        <p className='text-2xl font-bold text-center'>Purchase Your Event</p>
        {timer}
      </div>

      <div className='flex flex-col mt-7.5 gap-2'>
        <Controller
          name='email'
          control={control}
          rules={{ required: 'Email is required' }}
          render={({ field, fieldState }) => (
            <label>
              <div className='font-bold mb-2'>
                Email address to send invoice to
              </div>
              <input
                {...field}
                className={`bg-[#F2F2F2] appearance-none outline-none 
                focus:outline-none focus:ring-0 rounded-xl w-full h-12.5 text-sms
                 placeholder-icon-gray text-black px-5 
                 ${
                   fieldState.error
                     ? 'border-2 border-red-001'
                     : 'border border-secondary'
                 }`}
                type='email'
                placeholder='Enter email address'
                autoComplete='off'
                data-1p-ignore
              />
              <ErrorMessage
                errors={errors}
                name='email'
                render={({ message }) => (
                  <div className='text-sms text-red-002 mt-1'>{message}</div>
                )}
              />
            </label>
          )}
        />
      </div>

      <div className='mt-5 w-full h-5 text-sms text-red-002 text-center'>
        {err2s(error)}
      </div>
      <button
        type='submit'
        disabled={processing}
        className={`mt-1 btn-primary w-full h-12.5 flex justify-center items-center gap-2 font-bold`}
      >
        {processing && <Loading text='' />}
        {'Send Invoice'}
      </button>
      <div className='mt-5 flex justify-center items-center text-sms text-center'>
        Don’t have your credit card handy? Send an invoice to your manager or
        finance team to complete your booking.
      </div>
    </form>
  );
}

function PayMethodTab(props: {
  payOption: 'card' | 'invoice';
  updatePayOption: (payWith: 'card' | 'invoice') => void;
}) {
  const { payOption, updatePayOption } = props;
  return (
    <div className='flex items-center justify-center w-full mb-4'>
      <button
        type='button'
        className={`w-1/2 ${
          payOption === 'card'
            ? 'text-white font-bold'
            : 'text-icon-gray font-medium'
        }`}
        onClick={() => updatePayOption('card')}
      >
        Pay with credit card
      </button>
      <button
        type='button'
        className={`w-1/2 ${
          payOption === 'invoice'
            ? 'text-white font-bold'
            : 'text-icon-gray font-medium'
        }`}
        onClick={() => updatePayOption('invoice')}
      >
        Pay with invoice
      </button>
    </div>
  );
}

export function CheckoutCustomPay(props: {
  pack: DtoGamePack;
  headcount: number;
  returnUrl: string;
  invoiceReturnUrl: string;
}) {
  const { pack, headcount, returnUrl, invoiceReturnUrl } = props;

  const packPrice = useMemo(
    () => GamePackUtils.FindOneTimePrice(pack, headcount),
    [headcount, pack]
  );
  const [payOption, setPayOption] = useState<'card' | 'invoice'>('card');
  const [ready, setReady] = useState(false);
  const analytics = useOneTimePurchaseAnalytics();

  const { modal, timer } = useCompletionTimer(ready);
  const updatePayOption = (payOption: 'card' | 'invoice') => {
    analytics.trackEventPurchasePayOptionClicked(payOption);
    setPayOption(payOption);
  };

  // create OTP intent, so we can track and recover the checkout
  const timezone = useTimezone();
  useEffectOnce(() => {
    if (!packPrice) return;

    apiService.gamePack.createOTPIntent(pack.id, {
      priceId: packPrice.id,
      path: window.location.pathname,
      queries: new URLSearchParams(window.location.search).toString(),
      timezone,
    });
  });

  if (!packPrice) return null;

  const handleHeadcountChange = (headcount: number) => {
    const price = GamePackUtils.FindOneTimePrice(pack, headcount);

    const url = new URL(window.location.href);
    url.searchParams.set('headcount', headcount.toString());
    url.searchParams.set('price-id', price?.id || '');

    window.location.replace(url.toString());
  };

  return (
    <OTPCheckoutLayout
      pack={pack}
      progress={30}
      checkAccessible
      containerClassName='!w-full h-full !items-start'
      background={false}
    >
      <OTPCheckoutCustomProvider
        pack={fromDTOGamePack(pack)}
        price={packPrice}
        returnUrl={returnUrl}
      >
        <OTPCheckoutSidebar
          headcount={headcount}
          pack={fromDTOGamePack(pack)}
          onHeadcountChange={handleHeadcountChange}
        />

        <div className='relative'>
          <div className='hidden lg:flex absolute top-0 left-0 transform -translate-x-full pr-5'>
            <SocialProofJennifer />
          </div>
          <div className='relative'>
            <PayMethodTab
              payOption={payOption}
              updatePayOption={updatePayOption}
            />
            {modal && <div className='fixed inset-0 z-50'>{modal}</div>}
            {payOption === 'card' && (
              <CheckoutWithCardForm
                pack={fromDTOGamePack(pack)}
                ready={ready}
                setReady={setReady}
                timer={timer}
                returnUrl={returnUrl}
                headcount={headcount}
              />
            )}
            {payOption === 'invoice' && (
              <CheckoutWithInvoiceForm
                pack={fromDTOGamePack(pack)}
                packPrice={packPrice}
                returnUrl={invoiceReturnUrl}
                ready={ready}
                setReady={setReady}
                timer={timer}
              />
            )}
            <div
              className={`mt-9 w-full flex flex-col items-center gap-2.5 filter grayscale`}
            >
              <p className='text-sms text-icon-gray text-center italic'>
                Trusted by 90,000+ customers in 100+ countries
              </p>
              <CompaniesUsingLunaPark />
            </div>
          </div>
        </div>
      </OTPCheckoutCustomProvider>
    </OTPCheckoutLayout>
  );
}
