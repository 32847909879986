import { Link } from '@remix-run/react';
import React, { useCallback, useRef } from 'react';
import { useEffectOnce } from 'react-use';

import { useOneTimePurchaseAnalytics } from '../../analytics/oneTimePurchase';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useVisibilityChangeEffect } from '../../hooks/useVisibililityChangeEffect';
import { useCancelConfirmModalStateRoot } from '../ConfirmCancelModalContext';
import { XIcon } from '../icons/XIcon';
import { usePromotionCode } from '../Payment/usePromotionCode';

function ModalContainer(props: {
  onClose?: () => void;
  type: 'apply-discount' | 'contact-sales';
  children: React.ReactNode;
}) {
  const { children, onClose } = props;
  const analytics = useOneTimePurchaseAnalytics();
  useEffectOnce(() => {
    analytics.trackEventPurchaseModalViewed({
      type: props.type,
    });
  });

  return (
    <div className='relative w-121 bg-modal border border-secondary rounded-xl text-white'>
      {onClose && (
        <div className='absolute top-2 right-2'>
          <button type='button' onClick={onClose} className='btn'>
            <XIcon />
          </button>
        </div>
      )}

      {children}
    </div>
  );
}

function ApplyDiscountModal(props: {
  onContinue: () => void;
  onClose?: () => void;
}) {
  const { onContinue, onClose } = props;
  return (
    <ModalContainer type='apply-discount' onClose={onClose}>
      <div className='flex flex-col items-center justify-center gap-3.5 py-8'>
        <div className='font-bold text-2.5xl text-center text-green-001'>
          <span className='text-5xl'>👀</span>
          <br />
          Apply a 10% off discount?
        </div>
        <div className='text-center'>
          Click below to complete booking with a coupon
        </div>
        <button
          type='button'
          className='btn-primary w-75 h-15'
          onClick={onContinue}
        >
          Apply Discount
        </button>
      </div>
    </ModalContainer>
  );
}

function NotReadyModal(props: { onContinue: () => void; onClose: () => void }) {
  const { onContinue, onClose } = props;
  return (
    <ModalContainer type='contact-sales' onClose={onClose}>
      <div className='flex flex-col items-center justify-center gap-3.5 py-8'>
        <div className='font-bold text-2.5xl text-center text-green-001'>
          Not ready to book your experience?
        </div>
        <div className='text-center'>Request a sales call with our team</div>
        <Link to='https://lunapark.com/lp/book-meeting-ads' target='_blank'>
          <button
            type='button'
            className='btn-primary w-75 h-15'
            onClick={onContinue}
          >
            Schedule Now
          </button>
        </Link>
      </div>
    </ModalContainer>
  );
}

export function useExitIntent(onExitIntent: () => boolean) {
  const hasTriggeredExitIntentModal = useRef(false);
  const handleLostVisibility = useCallback(() => {
    if (hasTriggeredExitIntentModal.current) return;
    const didTrigger = onExitIntent();
    if (didTrigger) {
      hasTriggeredExitIntentModal.current = true;
    }
  }, [onExitIntent]);
  useVisibilityChangeEffect(() => handleLostVisibility);
}

const CHECKOUT_MODAL_STACK = ['discount', 'not-ready'] as const;

export function useCheckoutModalStack() {
  const [modal, triggerModal] = useCancelConfirmModalStateRoot();
  const [promotionCode, setPromotionCode] = usePromotionCode();
  const showedModals = useRef(new Set<(typeof CHECKOUT_MODAL_STACK)[number]>());

  const tryTriggerModal = useLiveCallback(() => {
    for (const config of CHECKOUT_MODAL_STACK) {
      if (showedModals.current.has(config)) continue;
      if (config === 'discount' && !!promotionCode) continue;
      switch (config) {
        case 'discount':
          triggerModal({
            kind: 'custom',
            element: (p) => {
              const onContinue = () => {
                setPromotionCode('10OFF');
                p.internalOnConfirm();
              };
              return <ApplyDiscountModal onContinue={onContinue} />;
            },
          });
          break;
        case 'not-ready':
          triggerModal({
            kind: 'custom',
            element: (p) => (
              <NotReadyModal
                onClose={p.internalOnCancel}
                onContinue={p.internalOnConfirm}
              />
            ),
          });
          break;
        default:
          break;
      }
      return true;
    }
    return false;
  });

  return [modal, tryTriggerModal] as const;
}
